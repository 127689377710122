import consumer from "./consumer"


$(document).ready(function() {

  const element = document.getElementById('room-score-id');
  const room_id_score = element ? element.getAttribute('data-room-id') : '';

  console.log('room-score-id: ' + room_id_score);



  if (room_id_score != '' ){
    consumer.subscriptions.create({channel: "ResultadosHeatChannel", room:room_id_score},{ 
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Conectado al ResultadosHeat channel: " + room_id_score)
        
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        var datajson = JSON.parse(data);
        console.log("datajson: " + datajson["data"])
        if (datajson["data"] == "reloj"){
          //Hacer clic en el link para actualizar la pagina (para que aparezca el reloj)
          document.getElementById("actualizar_pagina").click();
        }
        else {
          var iframe = document.getElementById('tabla_puntajes_heat');
          if (iframe !== null) {
            iframe.src = iframe.src; // Recarga el iframe estableciendo su src al mismo valor  
          }          

          var iframe_mobile = document.getElementById('tabla_puntajes_heat_mobile');
          if (iframe_mobile !== null) {
            iframe_mobile.src = iframe_mobile.src; // Recarga el iframe estableciendo su src al mismo valor  
          }
                  
          console.log("solicitud de recargar el iframe...")          
        }



      }
    });

  }


    
});
